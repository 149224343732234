import { Link, Redirect, Route } from "react-router-dom";
import React, { Component } from "react";
import {
  fetchProducts,
  setSearchString,
} from "../../redux/actions/productActions";

import Offcanvas from "./Offcanvas";
import { ReactSVG } from "react-svg";
import axios from "axios";
import { clear } from "redux-localstorage-simple";
import { connect } from "react-redux";
import { getProducts } from "../../helpers/product";
import HeaderMenu from "./HeaderMenu";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      searchValue: "",
      activateOffcanvas: false,
    };
    this.getMenuActiveStatus = this.getMenuActiveStatus.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.doSearch = this.doSearch.bind(this);
  }

  handleClickOffcanvas(e) {
    e.preventDefault();
    this.setState({ activateOffcanvas: !this.state.activateOffcanvas });
  }

  getMenuActiveStatus(status) {
    this.setState({
      activateOffcanvas: status,
    });
  }

  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }
  handleSearch(e) {
    clearTimeout(this.searchTimeout);
    this.setState({
      searchValue: e.target.value,
    });
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this.searchTimeout = setTimeout(
        () => this.doSearch(this.state.searchValue),
        250
      );
    }
  }
  componentDidMount() {
    this.mount = true;
    const el = document.querySelector("header");
    this.setState({ top: el.offsetTop });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `123px`)
      : (document.body.style.paddingTop = 0);
  }

  componentWillUnmount() {
    clear();
    this.mount = false;
  }

  doSearch(search) {
    this.props.fetchSearchedProducts(search);
  }

  render() {
    const { withSearchBar } = this.props;

    return (
      <header
        className={`sticker ${this.state.scroll > this.state.top ? "stick" : ""
          }`}
      >
        <div className="header-wrapper">
          <div className="container space-y--15">
            <div className="row align-items-center">
              <div className="col-6">
                {/* header logo */}
                <div className="header-logo">
                  <Link to={process.env.PUBLIC_URL + "/"} replace={true}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/logo-head-distri.png"
                      }
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <div
                className="col-6"
              >
                {/* header menu trigger */}
                <HeaderMenu handleClickOffcanvas={(e) => this.handleClickOffcanvas(e)} />
                <button
                  className="header-menu-trigger"
                  onClick={(e) => this.handleClickOffcanvas(e)}
                >
                  <ReactSVG
                    src={
                      process.env.PUBLIC_URL + "/assets/img/icons/menu-2.svg"
                    }
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="container">
            <div>
              <div className="col d-flex justify-content-center">
                {/* header search */}
                {withSearchBar ? (
                  <div className="header-search space-pb--15">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.doSearch(this.state.searchValue);
                        this.setState({
                          redirect: true,
                        });
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Buscar productos..."
                        value={this.state.searchValue}
                        onChange={(e) => {
                          this.handleSearch(e);
                        }}
                      />
                      <ReactSVG
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/icons/search-2.svg"
                        }
                      />
                    </form>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.redirect && (
          <Route>
            <Redirect to="/shop"></Redirect>
          </Route>
        )}
        {/* offcanvas menu */}
        <Offcanvas
          show={this.state.activateOffcanvas}
          activeStatus={this.getMenuActiveStatus}
          handleClickOffcanvas={this.state.handleClickOffcanvas}
        />
      </header>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    products: getProducts(state.productData.products, ownProps.limit),
    wishlistItems: state.wishlistData,
    searchString: state.productData.searchString,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    /* 
    addToWishlist: (item) => {
      dispatch(addToWishlistDispatch(item));
    },
    removeFromWishList: (item) => {
      dispatch(deleteFromWishlistDispatch(item));
    }, */
    fetchSearchedProducts: (search, order) => {
      /*       axios
        .get(
          `${process.env.REACT_APP_API_URL}/products?search=${search}&limit=20`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((response) => {
          dispatch(fetchProducts(response.data.data));
          dispatch(setSearchString(search));
        })
        .catch((error) => console.log(error)); */
      //BORRAR ESTO???
      dispatch(setSearchString(search));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
