import { Button, Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { Badge } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { getProductCartQuantity } from "../../helpers/product";
import { useSelector } from "react-redux";
import { NotificationAlert } from "./NotificationAlert";
import {
  getPushManagerSubscriptionsService,
  getServiceWorkerRegistration,
} from "../../services/pushNotificationService";
import { getProfileDataService } from "../../services/api";

const Footer = () => {
  const [showNotificationsAlert, setShowNotificationsAlert] = useState(false);
  const [userID, setUserID] = useState(5);
  const [currentPath, setCurrentPath] = useState("");
  let location = useLocation();
  useEffect(() => {
    setCurrentPath(location.pathname);
    console.log(location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    //ONLY TO TEST WITH SERGIOS'S USER
    const getUserData = async () => {
      let res = await getProfileDataService();
      let json = await res.json();
      if (res.status === 200) {
        return setUserID(json.data.id);
      } else {
        setUserID(5);
      }
    };
    getUserData();
    //ONLY TO TEST WITH SERGIOS'S USER

    getServiceWorkerRegistration().then((sw) => {
      if (sw) {
        getPushManagerSubscriptionsService().then((subs) => {
          console.log("subs", subs);
          let local_config = localStorage.getItem("no-notification-record");
          let local_config_date = localStorage.getItem(
            "no-notification-record-date"
          );
          let isToday = false;
          if (local_config_date) {
            let date = new Date(Number(local_config_date));
            const today = new Date();
            isToday =
              date.getDate() == today.getDate() &&
              date.getMonth() == today.getMonth() &&
              date.getFullYear() == today.getFullYear();
          }

          console.log(isToday);
          if (!subs && !local_config && !isToday && userID === 36) {
            setTimeout(() => {
              document.body.classList.add("modal-open-notification");
              setShowNotificationsAlert(true);
            }, 10000);
          } else {
            console.log(
              "Alerta para suscribirse a las notificaciones cancelada por el usuario"
            );
          }
        });
      }
    });
  }, [userID]);
  const cartData = useSelector((state) => state.cartData);

  let cartItemsQuantity = 0;

  cartData.forEach((item) => {
    cartItemsQuantity += getProductCartQuantity(cartData, item);
  });

  return (
    <footer>
      <div className="footer-nav-wrapper footer-nav-wrapper--styleTwo">
        <NavLink
          to={process.env.PUBLIC_URL + "/"}
          className={`footer-nav-single footer-nav-single--styleTwo ${
            currentPath === "/" ? "footer-nav-selected" : ""
          }`}
          replace={true}
        >
          <div className="menu-wrapper">
            <ReactSVG
              src={process.env.PUBLIC_URL + "/assets/img/icons/home.svg"}
            />
          </div>
        </NavLink>

        {/*         <Link
          to={process.env.PUBLIC_URL + "/wishList"}
          className={`footer-nav-single footer-nav-single--styleTwo`}
        >
          <div className="menu-wrapper">
            <ReactSVG
              src={process.env.PUBLIC_URL + "/assets/img/icons/heart-light.svg"}
            />
          </div>
        </Link> */}

        <NavLink
          to={process.env.PUBLIC_URL + "/cart"}
          className={`footer-nav-single footer-nav-single--styleTwo ${
            cartData.length > 0 ? "footer-nav-single--cartActive" : ""
          } ${currentPath === "/cart" ? "footer-nav-selected" : ""}`}
          replace={location.pathname != "/" ? true : false}
        >
          <div className="menu-wrapper">
            {cartData.length > 0 && (
              <Badge className="menu-cart--badge">{cartItemsQuantity}</Badge>
            )}
            <ReactSVG
              src={process.env.PUBLIC_URL + "/assets/img/icons/cart.svg"}
            />
          </div>
        </NavLink>
        <NavLink
          to={process.env.PUBLIC_URL + "/profile"}
          className={`footer-nav-single footer-nav-single--styleTwo ${
            currentPath === "/profile" ? "footer-nav-selected" : ""
          }`}
          replace={location.pathname != "/" ? true : false}
        >
          <div className="menu-wrapper">
            <ReactSVG
              src={process.env.PUBLIC_URL + "/assets/img/icons/profile.svg"}
            />
          </div>
        </NavLink>
        {showNotificationsAlert && (
          <NotificationAlert
            setShowNotificationsAlert={setShowNotificationsAlert}
          />
        )}
      </div>
    </footer>
  );
};

export default Footer;
