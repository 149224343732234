import React, { useState } from "react";
import {
  addToWishlistDispatch,
  deleteFromWishlistDispatch,
} from "../../redux/actions/wishlistActions";
import { connect, useDispatch } from "react-redux";
import {
  fetchProducts,
  setSearchString,
} from "../../redux/actions/productActions";
import { getDiscountPrice, getProducts } from "../../helpers/product";

import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import ProductCard from "../Cards/ProductCard";
import PropTypes from "prop-types";
import axios from "axios";
import { useLayoutEffect } from "react";
import { API_URL } from "../../services/api";
const AllProducts = ({
  products,
  wishlistItems,
  addToWishlist,
  removeFromWishList,
  fetchMoreProductsFromAPI,
  limit,
  searchString,
}) => {
  const [nextPage, setNextPage] = useState(2);

  const [hasMore, setHasMore] = useState(true);

  const dispatch = useDispatch();

  const fetchMoreProducts = async () => {
    /*     if (products.length >= limit) {
      setHasMore(false);
    }

    await fetchMoreProductsFromAPI(products, nextPage, searchString);
    setNextPage(nextPage + 1); */
  };

  useLayoutEffect(() => {
    /*     return () => {
      axios
        .get(process.env.REACT_APP_API_URL + `/products?limit=20`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          dispatch(fetchProducts(response.data.data));
          dispatch(setSearchString(""));
        })
        .catch((error) => console.log(error));
    }; */
  }, []);

  return (
    <div className="products-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* section title */}
            <h2 className="section-title space-mb--20">Productos</h2>
            {/* featured products */}
            <InfiniteScroll
              dataLength={products ? products.length : 0}
              next={fetchMoreProducts}
              hasMore={hasMore}
              loader={
                <h4 className="mt-5 text-center text-muted">Cargando...</h4>
              }
              endMessage={
                <h6 className="mt-5 text-center text-muted">
                  Ya los viste todos.
                  <Link
                    className="mt-2 d-block"
                    to={process.env.PUBLIC_URL + "/shop"}
                  >
                    Ver mas!
                  </Link>
                </h6>
              }
            >
              <div className="all-products-wrapper space-mb-m--20">
                <div className="row row-10">
                  {products &&
                    products.map((single) => {
                      const wishlistItem = wishlistItems.filter(
                        (wishlistItem) => wishlistItem.id === single.id
                      )[0];
                      return (
                        <ProductCard
                          product={single}
                          getDiscountPrice={getDiscountPrice}
                          wishlistItem={wishlistItem}
                          addToWishlist={addToWishlist}
                          removeFromWishList={removeFromWishList}
                          key={single.id}
                          horizontal={true}
                        />
                      );
                    })}
                </div>
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

AllProducts.propTypes = {
  addToWishlist: PropTypes.func,
  products: PropTypes.array,
  wishlistItems: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  return {
    products: getProducts(state.productData.products, ownProps.limit),
    wishlistItems: state.wishlistData,
    searchString: state.productData.searchString,
  };
};

/* const mapDispatchToProps = (dispatch) => {
  return {
    addToWishlist: (item) => {
      dispatch(addToWishlistDispatch(item));
    },
    removeFromWishList: (item) => {
      dispatch(deleteFromWishlistDispatch(item));
    },
    fetchMoreProductsFromAPI: (currentProducts, nextPage, searchString) => {
      console.log(searchString);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/products?page=${String(
            nextPage
          )}&limit=20${searchString ? `&search=${searchString}` : ""}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.data);
          dispatch(fetchProducts([...currentProducts, ...response.data.data]));
        })
        .catch((error) => console.log(error));
    },
  };
}; */

export default connect(mapStateToProps)(AllProducts);
/* export default connect(mapStateToProps, mapDispatchToProps)(AllProducts); */
