import { useEffect, useRef } from "react";

export const useEffectIf = (
  title,
  callback,
  condition,
  dependency = [],
  // debounce: corre el efecto diferido en milis
  // noFirst: no corre el efecto en la primer llamada
  options = {}
) => {
  const { debounce, noFirst } = options;
  const didMount = useRef(false);

  useEffect(() => {
    const mounted = !noFirst || didMount.current;
    if (condition && mounted) {
      if (debounce > 0) {
        process.env.REACT_APP_EFFECT_DEBUG &&
          console.log(
            `Effect -> ${title} en ${debounce} |`,
            ...dependency.filter((d) => !(d instanceof Function))
          );
        const timerId = setTimeout(callback, debounce);
        return () => clearTimeout(timerId);
      }
      process.env.REACT_APP_EFFECT_DEBUG &&
        console.log(
          `Effect -> ${title} |`,
          ...dependency.filter((d) => !(d instanceof Function))
        );
      return callback();
    }
    didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce, condition, ...dependency]);
};
