import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FiChevronUp } from "react-icons/fi/index";

const ScrollToTopButton = ({ windowSize }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  const movileStyle = {
    border: "1px solid #33420a",
    backgroundColor: `rgba(69, 175, 115, .8)`,
    position: "fixed",
    bottom: "3.5rem",
    right: "1.5rem",
  }

  const desktopStyle = {
    border: "1px solid #33420a",
    backgroundColor: `rgba(69, 175, 115, .8)`,
    position: "fixed",
    bottom: "4rem",
    right: '15vw'

  }

  return visible ? (
    <Button
      className="p-1"
      style={windowSize.width > 800 ? desktopStyle : movileStyle}
      onClick={scrollToTop}
    >
      <FiChevronUp size={windowSize.width > 800 ? 28 : 18} />
    </Button>
  ) : (
    <></>
  );
};

export default ScrollToTopButton;
