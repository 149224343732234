import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import {
  FiCheck,
  FiEdit,
  FiTrash2,
  FiShoppingCart,
} from "react-icons/fi/index";

import React, { useRef, useState } from "react";
import {
  addToCartDispatch,
  decreaseQuantityDispatch,
  deleteFromCartDispatch,
} from "../../../redux/actions/cartActions";
import { useDispatch, useSelector } from "react-redux";

import { Link, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useToasts } from "react-toast-notifications";
import { StockBadge } from "./StockBadge";
import { extractMarcaFrom, formatProductPrice } from "../../../utils";


export const DesktopCard = ({
  removeFromWishList,
  product,
  getDiscountPrice,
  wishlistItem,
  addToWishlist,
  key,
  horizontal,
  handleShowProductDetail }) => {
  const location = useLocation();
  const cartData = useSelector((state) => state.cartData);

  const cartItem = cartData.find((item) => item.id === product.id);

  const [disabledCard, setDisabledCard] = useState(false);
  const [focusInput, setFocusInput] = useState(false);

  const { addToast } = useToasts();

  const dispatch = useDispatch();

  const addToCart = () => {
    dispatch(addToCartDispatch(product, 1));
  };

  const removeOneFromCart = () => {
    if (cartItem.quantity > 0) {
      dispatch(decreaseQuantityDispatch(cartItem));
      addToast("Producto removido del carrito!", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
    }
  };

  const addProductToCart = () => {
    addToCart(product, 1);
    addToast("Producto agregado al carrito!", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 1500,
    });
  };
  const handleInputBlur = () => {
    setFocusInput(false);
    if (cartItem.quantity == 0) {
      dispatch(deleteFromCartDispatch(cartItem));
    }
  };
  const handleInputChange = (e) => {
    let newValue = e.target.value;
    if (newValue - cartItem.quantity != 0) {
      dispatch(addToCartDispatch(product, newValue - cartItem.quantity));
    }

    if (cartItem.quantity > newValue) {
      addToast("Producto removido del carrito!", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
    }
    if (cartItem.quantity < newValue) {
      addToast("Producto agregado al carrito!", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
    }
  };
  const currentRowRef = useRef();
  return (
    <div className='product-card-cont'>
      <Card style={{ width: '18rem', height: '22rem' }}>
        <Card.Img
          className='product-card-img-desk'
          style={{ marginLeft: '25%' }}
          src={
                          /* process.env.PUBLIC_URL +  */ product
              .galleryImage[0]
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              process.env.PUBLIC_URL + "/assets/LogoApp512.png";
          }}
          onClick={(e) => {
            handleShowProductDetail(product, currentRowRef);
          }}

        />
        <Card.Body>
          <Card.Title>
            <div
              className="product-card-body-title-desk"
              onClick={(e) => {
                handleShowProductDetail(product, currentRowRef);
              }}
            >
              {product.sku}
            </div>
            <div className="product-card-body-title-desk-price" >
              {product.discount && product.discount > 0 ? (
                <>
                  <div className="discounted-price">{`${formatProductPrice(
                    getDiscountPrice(product.price, product.discount)
                  )}`}</div>
                  <div className="main-price">{`${formatProductPrice(
                    product.price
                  )}`}</div>
                </>
              ) : (
                <div className="discounted-price">{`${formatProductPrice(
                  product.price
                )}`}</div>
              )}
            </div>
          </Card.Title>
          <Card.Text className={`product-card__title`}
            style={{
              fontSize: "0.85rem",
              fontWeight: "bold",
              marginBottom: "6px",
            }}
          >
            {extractMarcaFrom(product.marca)}
          </Card.Text>
          <Card.Text className={`product-card-title-desk`}
            style={{ fontSize: "0.85rem", marginBottom: 0, textOverflow: 'ellipsis' }}
          >
            {product.name}
          </Card.Text>
          <div
            className="d-flex btn-bottom mb-3 "
          >
            {focusInput || (cartItem && cartItem.quantity > 0) ? (
              <>
                {/*                                     <Button
                          variant="dark"
                          className="m-0 mr-2 p-1 pl-3 pr-3"
                          style={{ lineHeight: 1 }}
                          onClick={() => setDisabledCard(!disabledCard)}
                        >
                          {!disabledCard ? (
                            <FiEdit color="light" size={11} />
                          ) : (
                            <FiCheck color="light" size={11} />
                          )}
                        </Button> */}

                <ButtonGroup
                  size="sm"
                  style={{
                    fontSize: 11,
                    padding: 0,
                    margin: " 3px 0",
                    width: "6rem",
                  }}
                >
                  <Button
                    disabled={disabledCard}
                    className="p-1"
                    variant="dark"
                    style={{ lineHeight: 1.6, width: "1rem" }}
                    onClick={() => {
                      removeOneFromCart();
                    }}
                  >
                    {cartItem && cartItem.quantity === 1 ? (
                      <span className="ml-1 mr-1">
                        <FiTrash2 color="light" size={14} />
                      </span>
                    ) : (
                      <span className="ml-2 mr-2">-</span>
                    )}
                  </Button>
                  <Button
                    className="p-1"
                    variant="dark"
                    role="input"
                    style={{ lineHeight: 1, border: "none" }}
                  >
                    {/*  <span className="ml-2 mr-2">
                              {cartItem ? cartItem.quantity : 0}
                            </span> */}
                    <input
                      type="text"
                      onChange={handleInputChange}
                      onFocus={() => setFocusInput(true)}
                      onBlur={handleInputBlur}
                      value={
                        cartItem
                          ? cartItem.quantity == 0
                            ? ""
                            : cartItem.quantity
                          : 0
                      }
                      style={{
                        textAlign: "center",
                        width: "2rem",
                        border: "none",
                        backgroundColor: "inherit",
                        color: "white",
                      }}
                    />
                  </Button>
                  <Button
                    disabled={disabledCard}
                    className="p-1"
                    variant="dark"
                    style={{ lineHeight: 1.6, width: "1rem" }}
                    onClick={() => {
                      addProductToCart();
                    }}
                  >
                    <span className="ml-2 mr-2">+</span>
                  </Button>
                </ButtonGroup>
                <Link
                  to={process.env.PUBLIC_URL + "/cart"}
                  className="carrito-product-button"
                  replace={location.pathname != "/" ? true : false}
                >
                  <FiShoppingCart className="carrito-product-button-icon" />
                </Link>
              </>
            ) : (
              <>
                <div className="w-100 stock-button-group">
                  <StockBadge
                    city="Gral. Pico"
                    name="PICO"
                    stock={product.realtime_stock.PICO}
                  />

                  <StockBadge
                    city="Mar del Plata"
                    name="MDP"
                    stock={product.realtime_stock.MDP}
                  />

                  <StockBadge
                    name="BA"
                    city="CABA"
                    stock={product.realtime_stock.CABA}
                  />
                </div>

                <Button
                  variant="primary"
                  className="add-product-button-desk"
                  onClick={() => addProductToCart()}
                  disabled={disabledCard}
                >
                  Agregar
                </Button>
              </>
            )}
          </div>
        </Card.Body>
      </Card>

    </div>
  )
}
