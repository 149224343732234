// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCtr5pOFr4DUX-qUp44IYiRvgQsBxRzuTw",
  authDomain: "applupita.firebaseapp.com",
  projectId: "applupita",
  storageBucket: "applupita.appspot.com",
  messagingSenderId: "538601318871",
  appId: "1:538601318871:web:cfc539422f86bc683c4392",
  measurementId: "G-GC5R6QSTQF"
};


// Initialize Firebase

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export const ga = (event_name, data) => {
  console.log('logEvent', event_name, { value: data });
  logEvent(analytics, event_name, { value: data });
}