export const API_URL = process.env.REACT_APP_API_URL;

export function setProntoPagoService(value) {
  let formData = new FormData();
  formData.append("prontopago", value);
  return fetch(API_URL + "/user/prontopago", {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
  });
}
export function setRentabilidadService(value) {
  let formData = new FormData();
  formData.append("rentabilidad", value);
  return fetch(API_URL + "/user/rentabilidad", {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
  });
}
export function setIvaService(value) {
  let formData = new FormData();
  formData.append("iva", value);
  return fetch(API_URL + "/user/iva", {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
  });
}
export function getProfileDataService() {
  return fetch(API_URL + "/user/profile", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
  });
}
export function setOrderService(orderData) {
  return fetch(API_URL + "/order", {
    method: "POST",
    body: JSON.stringify(orderData),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
  });
}
export function getFavsService() {
  return fetch(API_URL + "/favorites", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
  });
}
export function getEquivalencias(code) {
  return fetch(API_URL + "/products/" + code + "/equivalencias", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
  });
}
export function getProductData(code) {
  return fetch(API_URL + "/products/" + btoa(code), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
  });
}
